import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import styled from  'styled-components';

function App() {
  const [showVideo, setShowVideo] = useState(false);
 
  return (
    <Wrapper>
      {
        !showVideo && (
          <Modal>
            Привет моя именинница! Ну вот, ты уже в шаги от своего  главного подарка. Но прежде чем получить его, я хочу предупредить тебя. Увиеденное может тебя ШОКИРОВАТЬ и УДИВИТЬ а так же еще рассмешить и порадовать!
            <Action onClick={() => setShowVideo(true)}>
              Я согласна с последствиями! Получить подарок.
            </Action>
           </Modal>
        )
      }
      {showVideo && (<Frame width="560" height="315" src="https://www.youtube.com/embed/MQhKaSzECpQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Frame>)}
    </Wrapper>
  );
}

export default App;



const Action = styled.div`
  padding: 10px;
  margin: 30px 0;
  border: 1px solid rgb(62 62 62);
  cursor: pointer;
  background-color: #040404;

  :hover {
    opacity: 0.7;
  }
  
`

const Wrapper = styled.div`
  background-color: #000;
  width: 100vw;
  height: 100vh;
  color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;


const Modal = styled.div`
  position: fixed;
  border: 1px solid rgb(62 62 62);
  padding: 20px;
  max-width: 600px;
  text-align: center;
  font-size: 18px;
  line-height: 160%;
}
`;


const Frame = styled.iframe`
  width: 80vw;
  height: 50vh;
`;